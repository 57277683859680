import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import user from '@/store/user'
import app from '@/store/app'
import event from '@/store/event'
import store from '@/store/store'
import reacts from '@/store/reacts'
import entities from '@/store/entities'
import group from '@/store/group'
import marketplace from '@/store/marketplace'
import notifications from '@/store/notifications'
import axios from 'axios'
import profile from './profile';
import posts from './posts';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    apiServer : process.env.VUE_APP_SERVER,
    isLoggedIn: false,
    token: localStorage.getItem('token') || '',
    userData: [],
    entitiesCount: []
  },
  mutations: {
    login (state) {
      state.isLoggedIn = true;
    },
    logout (state) {
      state.isLoggedIn = false;
      localStorage._uid = null;
      localStorage._at = null;
      localStorage._ck = null;
      state.userData = [],
      state.entitiesCount = []
    },
    saveUserData: (state, user) => {
        state.userData = user
    },
    auth_success: (state) =>{
      state.isLoggedIn = true
    },
    saveEntitiesCount: (state , entities_count) => {
      state.entitiesCount = entities_count
    }
    
  },
  actions: { 
    login({commit},payload){
      return  new Promise((resolve, reject) => {
        axios({
          url: 'authentication/login',
              method: 'post',
              data: {
                email_address: payload.login_EmailAdress,
                password: payload.login_Password,
                keepMeLoggedIn: payload.login_Accept,
              }
        }).then(response => {
          if (response.data['status'] == 'login_success') {
            const token  = response.data['body']['token'];
            var user = {
              id: response.data['body']['user_id'],
              first_name: response.data['body']['first_name'],
              last_name: response.data['body']['last_name'],
              email_address: response.data['body']['email_address'],
              profile_photo: response.data['body']['profile_photo'],
              cover_photo: response.data['body']['cover_photo'],
            }
            var entities_count = {
              users: response.data['body']['entities']['users_count'],
              groups: response.data['body']['entities']['groups_count'],
              events: response.data['body']['entities']['events_count']
            }
            localStorage.setItem('token',token)
            localStorage.setItem('_uid', user.id)
            if(user.profile_photo == 'not_available'){
              user.profile_photo == require('@/assets/default_profile.webp')
            }
            commit('saveUserData',user);
            commit('auth_success')
            commit('saveEntitiesCount',entities_count)
            return resolve({message: 'success'})
          }else if( response.data['status'] == 'login_invalid'){
            return resolve({
              message: 'login_invalid'
            })
          }else if (response.data['status'] == 'activation_required') {
            return resolve({
              message: 'activation_required',
              key: response.data['body']['activation_key'],
              email: response.data['body']['email']
            })
          }
        })
        .catch(err => {
          localStorage.removeItem('token')
          reject(err)
        })
      })
    },
    logout({commit}){
      return new Promise((resolve) =>{
        commit('entities/flush');
        commit('reacts/flush');
        commit('logout')
        localStorage.removeItem('token')
        localStorage.removeItem('_uid')
        window.localStorage.removeItem('vuex')
        delete axios.defaults.headers.common['Authorization']
        return resolve('logged out')
      })
    },

  },
  modules: {
    user: user,
    app: app,
    event: event,
    profile: profile,
    store: store,
    group: group,
    posts: posts,
    reacts: reacts,
    entities: entities,
    marketplace:marketplace,
    notifications: notifications
  },
  plugins: [
    createPersistedState()
  ],
})
