const notifications = {
    state: {
        items: [],
        page: 1
    },
    mutations: { 
        nextPage(state) {
            state.page++;
        }
    },
    namespaced: true
}
export default notifications