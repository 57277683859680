import Vue from 'vue'
import App from './App.vue'
import './quasar'
import router from './router'
import store from './store'
import moment from 'moment'
import globalstyle from './styles/globalstyle.css'
import mediaqueries from './styles/mediaqueries.css'

Vue.config.productionTip = false

// Load axios.
import axios from 'axios'
import User from '@/mixins/User'

// Make the default client to Axios.
Vue.prototype.$http = axios;
const token  = localStorage.getItem('token')
if (token){
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}

// Load the baseURL from the ENV file.
Vue.prototype.$http.defaults.baseURL = process.env.VUE_APP_APISERVER;

new Vue({
  router,
  store,
  moment,
  mixins: [User],
  globalstyle,
  mediaqueries,
  render: h => h(App)
}).$mount('#app')
