import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
import store from '../store'
Vue.use(VueRouter)
Vue.use(Meta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
})
function requireLoggedIn(to, from, next) {
  if (store.state.isLoggedIn) {
    next();
  } 
  else {
    next('/login');
  }
}
const routes = [
  {
    path: '/',
    name: 'Home2',
    component: () => import('@/views/Login.vue'),
    beforeEnter: requireLoggedIn,
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    beforeEnter: requireLoggedIn,
    alias: '/'
  },
  {
    path: '/notfound',
    name: 'PageNotFound',
    component: () => import('@/views/PageNotFound.vue')
  },
  {
    path: '/activation',
    name: 'Activation',
    component: () => import('@/views/Activation.vue')
  },
  {
    path: '/registration',
    name: 'Registration',
    component: () => import('@/views/Registration.vue')
  },
  {
    path: '/account-delete',
    name: 'AccountDeletion',
    component: () => import('@/views/AccountDeletion.vue')
  },
  {
    path: '/profile',
    name: 'Me',
    beforeEnter: requireLoggedIn,
    component: () => import('@/views/Profile.vue')
  }, 
  {
    path: '/profile/setup',
    name: 'ProfileSetup',
    beforeEnter: requireLoggedIn,
    component: () => import('@/views/ProfileSetup.vue')
  },
  {
    path: '/profile/settings',
    name: 'ProfileSettings',
    beforeEnter: requireLoggedIn,
    component: () => import('@/views/ProfileSettings.vue')
  },
  {
    path: '/profile/:id',
    name: 'Profile',
    beforeEnter: requireLoggedIn,
    component: () => import('@/views/Profile.vue')
  },
  {
    path: '/posts/:id',
    name: 'Post',
    beforeEnter: requireLoggedIn,
    component: () => import('@/views/Post.vue')
  },
  {
    path: '/posts/:id/edit',
    name: 'PostEditor',
    beforeEnter: requireLoggedIn,
    component: () => import('@/views/PostEditor.vue')
  },
  {
    path: '/groups',
    name: 'Groups',
    component: () => import('@/views/Groups.vue')
  },
  {
    path: '/groups/:id',
    name: 'Group',
    component: () => import('@/views/Group.vue')
  },
  {
    path: '/groups/invitation/:invite',
    name: 'GroupInvite',
    component: () => import('@/views/EntityInvite.vue')
  },
  {
    path: '/events/invitation/:invite',
    name: 'EventInvite',
    component: () => import('@/views/EntityInvite.vue')
  },
  {
    path: '/groups/:id/settings',
    name: 'GroupSettings',
    component: () => import('@/views/GroupSettings.vue')
  },
  {
    path: '/groups/:user/grouplist/',
    name: 'GroupList',
    component: () => import('@/components/Group/GroupList.vue')
  },
  {
    path: '/events',
    name: 'Events',
    component: () => import('@/views/Events.vue'),
  },
  {
    path: '/events/:user/eventlist/',
    name: 'EventList',
    component: () => import('@/components/Event/EventList.vue'),
    props:true
  },
  {
    path: '/event/:id',
    name: 'Event',
    component: () => import('@/views/Event.vue'),
    props:true
  },
  {
    path: '/event/:id/settings',
    name: 'EventSettings',
    component: () => import('@/components/Event/EventSettings.vue'),
    props:true
  },
  // {
  //   path: '/marketplace',
  //   name: 'Marketplace',
  //   component: () => import('@/views/Marketplace.vue'),
  // },
  // {
  //   path: '/marketplace/:id/items',
  //   name: 'MarketplaceItemList',
  //   component: () => import('@/components/Marketplace/MarketList.vue'),
  // },
  // {
  //   path: '/marketplace/:id',
  //   name: 'MarketplaceItem',
  //   component: () => import('@/views/MarketplaceItem.vue'),
  // },
  // {
  //   path: '/marketplace/:id/settings',
  //   name: 'MarketplaceItemSettings',
  //   component: () => import('@/components/Marketplace/MarketItemSettings.vue'),
  // },
  {
    path: '/marketplace',
    name: 'Marketplace',
    component: () => import('@/views/ClosedMarketplace.vue'),
  },
  {
    path: '/marketplace/:id/items',
    name: 'MarketplaceItemList',
    component: () => import('@/views/ClosedMarketplace.vue'),
  },
  {
    path: '/marketplace/:id',
    name: 'MarketplaceItem',
    component: () => import('@/views/ClosedMarketplace.vue'),
  },
  {
    path: '/marketplace/:id/settings',
    name: 'MarketplaceItemSettings',
    component: () => import('@/views/ClosedMarketplace.vue'),
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('@/components/Search/Search.vue'),
  },
  {
    path: '/create/:type/',
    name: 'CreateEntity',
    component: () => import('@/views/CreateEntity.vue'),
  },
  {
    path: '/friends',
    name: 'Friends',
    component: () => import('@/views/Friends.vue'),
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: () => import('@/views/Gallery.vue'),
  },
  {
    path: '/forgotpassword',
    name: 'ForgotPassword',
    component: () => import('@/views/ForgotPassword.vue'),
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: () => import('@/views/TermsAndConditions.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('@/views/PrivacyPolicy.vue'),
  },
  {
    path: '*',
    name: 'NotFound404',
    component: () => import('@/views/PageNotFound.vue')
  },
  {
    path: '*',
    name: 'EntityDeleted',
    component: () => import('@/views/EntityDeleted.vue')
  },
]
const router = new VueRouter({
  mode: 'history',
  routes
})
export default router
