const posts = {
    state: () => ({
        posts: []
    }),
    getters: { 
        get: (state) => (id) => {
            return state.posts.find(post => post.id === id)
        }
    },
    mutations: { 
        save(state, payload) {
            if (!state.posts.find(post => post.id === payload.id)) {
                state.posts.push(payload)
            }
        }
    },
    namespaced: true
}
export default posts