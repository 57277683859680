import axios from 'axios'

const group = {
    state: () => ({
        allGroups: [],
        grouplist: [],
        suggestedlist: [],
        cover_photo: '',
        group:[{
            id:'',
            name:'',
            description:'',
            privacy:'',
            isMember:'',
            status:'',
            inviteData:[]
        }]
    }),

    getters: {
        getGroups: state => state.allGroups,
        getGroupList: state => state.grouplist,
        getSuggestedGroups: state => state.suggestedlist,
        getGroup: state => state.group,
        getCoverPhoto: state => state.cover_photo
    },

    actions: {
        uploadPhoto({commit},payload){
            return new Promise((resolve,reject) => {
                axios({
                    url:'entities/' + payload.id +'/setCoverPhoto',
                    method: "POST",
                    headers: {'Content-Type':'multipart/form-data'},
                    data: payload.data
                })
                .then((response) =>{
                    if (response.data['status'] == 'cover_photo_updated') {
                            commit('updateCoverPhoto', response.data['body']['file_url'] )
                        return resolve('success');
                    }else{
                        commit('updateCoverPhoto',require('@/assets/default_cover2.webp'))
                        return resolve('success')
                    }
                })
                .catch(err =>{
                    return reject(err)
                })
            })
        },
        getCoverPhoto({state},payload){
            return new Promise((resolve,reject) => {
                axios({
                    url:'/entities/' + payload.id + '/getCoverPhoto',
                    method: "GET"
                })
                .then((response) => {
                    if (response.data['status'] == 'cover_photo_retrieved') {
                        if (response.data['body']['file_id']) {
                            return resolve({
                                status: 'success',
                                image: response.data['body']['file_url']
                            });
                        }  
                    }
                    if(response.data['status'] == 'not_available'){
                        return resolve({
                            status: 'success',
                            image: require('@/assets/default_cover2.webp')
                        })
                    }
                })
                .catch(err =>{
                    console.log(state.event)
                    return reject(err)
                })
            })
        },
        fetchgrouplist ({state},payload) {
            return new Promise ((resolve,reject) => {    
                axios({
                    url:'/entities?user_id='+payload+'&type=1',
                    method: 'GET',
                })
                .then((response) => {
                    if(response.data['status'] == "entities_retrieved"){
                        state.grouplist = response.data['body']['entities']
                        var pagination = response.data['body']['pagination']
                        return resolve({
                            status:'success',
                            groups: state.grouplist,
                            pagination: pagination
                        })
                    }else{
                        return resolve({
                            status: 'no_entities'
                        })
                    }
                })
                .catch(err => {
                    console.log(state.eventlist)
                    return reject(err)
                })
            })
        },
    },

    mutations: {
        updateCoverPhoto(state,payload) {
            state.cover_photo = payload
        }
    },
    namespaced:true
    
}

export default group