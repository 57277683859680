import axios from 'axios'
const user = {
    state: () => ({
        first_name: null, 
        middle_name: null,
        full_name: null,
        last_name: null,
        email_address: null,
        id: null,
        cover_photo:  require('@/assets/default_cover2.webp'),
        user_friends: [],
        profile_photo: ''
    }),
    getters: { 
        getFullName: state => {
            return state.first_name + ' ' + state.last_name;
        },
        getCoverPhoto: state => {
            return state.cover_photo
        }
    },
    mutations: { 
        save(state, payload) {
            state.id = payload.id;
            state.first_name = payload.first_name,
            state.middle_name = payload.middle_name,
            state.last_name = payload.last_name,
            state.email_address = payload.email_address,
            state.profile_photo = payload.profile_photo,
            state.cover_photo = payload.cover_photo
        },
        logout(state) {
            state.id = null;
            state.first_name = null,
            state.middle_name = null,
            state.last_name = null,
            state.email_address = null,
            state.cover_photo = '',
            state.profile_photo = ''
        },
        saveProfilePhoto(state, payload) {
            state.profile_photo = payload;
        },
        changeProfilePicture(state, payload) {
            state.profile_photo = payload;
        },
        updateUserFriends(state,payload) {
            state.user_friends = payload
        },
        updateProfileCoverPhoto(state,payload){
            state.cover_photo = payload
        }
    },
    actions: { 
        setProfiledata({state},payload){
            state.id = payload.id
            state.first_name = payload.first_name
            state.last_name = payload.last_name
            state.email_address = payload.email_address
            state.full_name = payload.first_name + ' ' + payload.last_name

            if (payload.profile_photo == 'not_available') {
                state.profile_photo =  require('@/assets/default_profile.webp');
            } else {
                state.profile_photo = payload.profile_photo;
            }

            if (payload.cover_photo == 'not_available') {
                state.cover_photo =  require('@/assets/default_cover2.webp');
            } else {
                state.cover_photo = payload.cover_photo;
            }
        },
        loadProfile({state,commit},payload){
            return new Promise ((resolve) => {
                axios({
                    url: 'profiles/' + payload,
                    method: 'GET'
                })
                .then(response => {
                    if(response.data['status'] == 'setup_required') {
                        let user_id = response.data['body']['user']['id'];
                        localStorage.setItem('is_setup_required', true);
                        return resolve({
                            message: 'setup_required',
                            user_id: user_id
                        })
                    }
                    if(response.data['status'] == 'profile_retrieved') {
                         response.data['body']['profile_photo'] != 'not_available' ? this.profilePhoto = response.data['body']['profile_photo'] : this.profilePhoto =  require('@/assets/default_profile.webp');
                        if(response.data['body']['profile']['id'] == state.id){
                            commit('changeProfilePicture',this.profilePhoto )
                        }
                        return resolve({
                            message: 'profile_retrieved',
                            isOwner: response.data['body']['isOwner'],
                            isFriends: response.data['body']['isFriends'],
                            profile: response.data['body']['profile'],
                            coverPhoto:  response.data['body']['cover_photo'],
                            profilePhoto: this.profilePhoto
                        })
                    }
                }).catch((err) => {
                    if (err.response.status === 404) {
                        return resolve({
                            message: 'profile_not_found'
                        })
                    }
                })
            });
        },

        
    },
    
    namespaced: true
}
export default user