const reacts = {
    state: () => ({
        cache: [],
    }),
    getters: {
        read: (state) => (id) => {
            let react = state.cache.find(post => post.id === id);
            if (react !== undefined) {

                if (react.timestamp === undefined) {
                    react.timestamp = Date().getTime();
                }

                let currentTime = Date.now() - react.timestamp;
                currentTime = Math.floor(currentTime / 1000);
                if (3600 < currentTime) {
                    return undefined;
                }

                return react;
            }

            return undefined;
        }
    },
    mutations: {
        save (state, payload) {
            let react = state.cache.find(post => post.id === payload.id);

            if (react !== undefined) {
                if (payload.value !== undefined) {
                    react.value = payload.value;
                }
                if (payload.count !== undefined) {
                    react.count = payload.count;
                }
                react.timestamp = new Date().getTime();
            } else {
                if (payload.timestamp === undefined) {
                    payload.timestamp = new Date().getTime();
                }

                state.cache.unshift(payload)
            }

        },
        flush (state) {
            state.cache = [];
        }
    },
    namespaced: true
}
export default reacts