//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    UserDropdown: () => import('@/components/NavbarDropdowns/UserDropdown.vue'),
    Notification: () => import('@/components/Notification/Notification.vue'),
    CreateEntityDropdown: () => import('@/components/NavbarDropdowns/CreateEntityDropdown.vue'),
    Drawer: () => import('@/components/Drawer.vue'),
    MobileFooterNav: () => import('@/components/MobileFooterNav.vue'),
    SearchBar: () => import('@/components/Search/SearchBar'),
  },
  data(){
    return {
      leftDrawer: this.$store.state.app.leftDrawer,
      firstname: this.$store.state.user.first_name,
      email_address: this.$store.state.user.email_address,
      isLoggedIn: this.$store.state.isLoggedIn,
      onLoadAnimate: false,
      lightmode: true,
    }
  },
  
  metaInfo:{
    titleTemplate: '%s Golfbean',
    meta:[
      { description: 'A platform for golfers made by golfers.'}
    ]
  },
  
  methods: {
    // Set Preloader
     showLoading () {
      this.$q.loading.show({
        message: ' <img src='+require("@/assets/gb-favicon-white.png")+' class="login-logo">',
        sanitize: false,
        backgroundColor: '#14181b',
        spinnerSize: 0,
      })
      this.timer = setTimeout(() => {
        this.$q.loading.hide()
        this.timer = void 0
      }, 1000)
    },
    controlDrawer(){
      this.leftDrawer = !this.leftDrawer
    }
  },
  mounted(){
      this.onLoadAnimate = true;
    },
  computed: {
    isMobile  (){
      return this.$q.screen.width < 992 && this.$q.platform.is.mobile ? true : false;
    }
  },
  created()
  {
    this.showLoading()
    let system = this;
    this.$http.interceptors.request.use(function (config) {
      config.headers.Authorization = localStorage.token;
      return config;
    }, function (error) {
      console.error("The Axios HTTP request has failed. Please see the logs for more details.")
      console.error(error);
      return Promise.reject(error);
    });

    this.$http.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      if(error.response.status == 401) {
        system.$store.commit('logout');
        system.$store.commit('user/logout');
        system.$router.push('/login');
      }
      if(error.response.status == 400) {
        //
      }
      // Intercept a 500 error.
      if (error.response.status == 500) {
        //
      }
      return Promise.reject(error);
    });
    if (window.location.href.includes('/registration')) {
        this.lightmode = true;
    } 
   }
  }
