const app = {
    state: () => ({
        isConnected: false,
        defaultCoverPhoto: require('@/assets/default_cover2.webp'),
        defaultProfilePhoto: require('@/assets/default_profile.webp'),
        leftDrawer: false,
        viewImg: false,
        img_modal_src: '',
        showLeftSidebar: true,
    }),
    getters: { 
        
    },
    mutations: { 
        OpenImgViewer: (state, img) =>(
            state.viewImg = true,
            state.img_modal_src = img
        ),
        hideLeftSidebar: (state) => {
            state.showLeftSidebar = false
        },
        displayLeftSidebar:(state) => {
            state.showLeftSidebar = true
        },
    },
    actions: { 
        OpenImgViewer ({commit},payload){
            commit('OpenImgViewer', payload)
        }
    },
    
}
export default app