import axios from 'axios'
const marketplace = {
    state: () => ({
        items: [],
        item: [],
        item_list: [],
        item_medias: [],
    }),

    mutations: {
        updateItemsData: (state, items) => (state.items = items),
        updateItemData: (state, item) => (state.item = item),
        UpdateItemList: (state, items) => (state.item_list = items),
        updateItem_Medias: (state, medias) => (state.item_medias = medias)
    },

    getters: {
    },

    actions: {
        createItem({state},payload){
            return new Promise((resolve,reject) => {
                axios({
                    url:'marketplace',
                    method:'POST',
                    data:payload.data
                }).then((r) => {
                    if(r.data['status'] == 'marketplace_item_created'){
                        return resolve({
                            status:'success',
                            data: r.data['body']['marketplace_item']['id']
                        })
                    }
                })
                .catch(err =>{
                    console.log(state.item)
                    return reject(err)
                })
            })
        },

        fetchItem({commit,state},payload){
            return new Promise((resolve,reject) => {
                axios({
                    url:'marketplace/'+payload.id,
                    method: "GET",
                }).then((r) => {
                    if(r.data['status'] == 'marketplace_item_retrieved'){
                        commit('updateItemData',r.data['body']['marketplace_item'])
                        return resolve({
                            status:'success',
                            data: state.item
                        })
                    }
                })
                .catch(err => {
                    return reject(err)
                })
            })
        },

        UpdateItem({state},payload){
            return new Promise((resolve,reject) => {
                axios({
                    url:'marketplace/'+payload.id,
                    method: "PUT",
                    data: payload.data
                }).then((r) => {
                    if(r.data['status'] == 'marketplace_item_updated'){
                        return resolve({
                            status:'success'
                        })
                    }else{
                        return reject('Failed')
                    }
                })
                .catch(err => {
                    console.log(state.item)
                    return reject(err)
                })
            })
        },

        deleteItem({state},payload){
            return new Promise((resolve,reject) => {
                axios({
                    url:'marketplace/'+payload.id,
                    method: "DELETE",
                }).then((r) => {
                    if(r.status == 200){
                        return resolve({
                            status:'success'
                        })
                    }
                })
                .catch(err => {
                    console.log(state.item_list)
                    return reject(err)
                })
            })
        },

        addItemMedia({state},payload){
            return new Promise((resolve,reject) => {
                axios({
                    url:'marketplace/'+payload.id+'/medias',
                    method: "POST",
                    data: payload.data
                }).then((r) => {
                    if(r.data['status'] == 'marketplace_item_updated'){
                        return resolve({
                            status: 'success',
                            id: payload.id
                        })
                    }
                })
                .catch(err => {
                    console.log(state.item_medias)
                    return reject(err)
                })
            })
        },

        removeItemMedia({state},payload){
            return new Promise((resolve,reject) => {
                axios({
                    url: 'marketplace/'+payload.id+'/medias?media_id='+payload.media_id,
                    method: "DELETE",
                }).then((r) => {
                    if(r.data['status'] == 'media_deleted'){
                        return resolve({
                            status: 'success'
                        })
                    }
                })
                .catch(err => {
                    console.log(state.item_medias)
                    return reject(err)
                })
            })
        },

        fetchItems({state,commit},payload){
            return new Promise((resolve, reject) => {
                axios({
                    url:'marketplace?page='+payload.page+'&q='+payload.q+'&user_id='+payload.user_id+'&limit='+payload.limit,
                    method: 'GET',
                })
                .then((r) => {
                    if(r.data['status'] == 'items_retrieved'){
                        commit('updateItemsData',r.data['body']['marketplace_items'])
                        return resolve({
                            status: 'success',
                            data: state.items,
                            pagination: r.data['body']['pagination']
                        })
                    }else{
                        return resolve({
                            status: 'no_items_found'
                        })
                    }
                })
                .catch(err => {
                    return reject(err)
                })
            })
        },

    },

    namespaced: true
}

export default marketplace