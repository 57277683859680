
export default {
    computed: {
        getFullName() {
            return this.$store.user.first_name + ' ' + this.$store.user.last_name
        },
        getUserId() {
            return this.$store.user.id;
        }
    }
}
