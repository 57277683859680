import axios from 'axios'
const event = {
    state: () => ({
        events: [],
        upcoming_events: [],
        event: [],
        eventlist: [],
        eventsTable: [],
        date: new Date(),
        calendar_events: {
            events:[],
            events_data: []
        },
        event_scoreboard: [],
        event_pars: [],
        event_totals: []
    }),

    getters: {
        allEvents: state => state.events,

        allUpcomingEvents: state => state.upcoming_events,

        getEvent: state => state.event,

        allEventList: state => state.eventlist,

        allEventsTable: state => state.eventsTable,

        getEventList: state => state.eventlist,
        
        getEventsTable: state => state.getEventsTable,

        getCalendarEvents: state => state.calendar_events,

        getEventScoreboard: state => state.event_scoreboard,

        getEventPars: state => state.event_pars,

        getEventTotals: state => state.event_totals,

    },

    actions: {
        fetchEvents ({state, commit}, payload) {
            return new Promise ((resolve,reject) => {    
                axios({
                    url:'/events?page=' + payload.page + '&limit=' + 10 + '&q=' + payload.q,
                    method: 'GET',
                })
                .then((response) => {
                    if (response.data['status'] == 'events_retrieved'){
                        commit('updateEvents', response.data['body']['events'])
                        let pagination = response.data['body']['pagination']
                        return resolve({
                            status:'success',
                            pagination: pagination
                        })
                    } else {
                        return resolve({
                            status: 'no_events'
                        })
                    }
                })
                .catch(err => {
                    console.log(state.events)
                    return reject(err)
                })
            })
        },

        fetchEventList ({state,commit},payload) {
            return new Promise ((resolve,reject) => {    
                axios({
                    url:'/entities?user_id='+payload+'&type=2',
                    method: 'GET',
                })
                .then((response) => {
                    if(response.data['status'] == "entities_retrieved"){
                        state.eventlist = response.data['body']['entities']
                        var pagination = response.data['body']['pagination']
                        commit('sortCalendarEvents',state.eventlist)
                        return resolve({
                            status:'success',
                            eventlist: state.eventlist,
                            pagination: pagination
                        })
                    }else{
                        return resolve({
                            status: 'no_entities'
                        })
                    }
                })
                .catch(err => {
                    console.log(state.eventlist)
                    return reject(err)
                })
            })
        },


        fetchEvent ({commit},payload) {
           return new Promise((resolve) =>{
               axios({
                   url:'/events/'+payload,
                   method:'GET'
               }).then((response) => {
                   switch (response.data['status']) {
                        case 'event_retrieved':
                            commit('updateEvent',response.data['body']['event'])
                            return resolve({
                                status:'success',
                                name:response.data['body']['event']['name'],
                                isAdmin: response.data['body']['isAdmin'],
                                isMember: response.data['body']['isMember'],
                                isOwner: response.data['body']['isOwner'],
                                membersCount: response.data['body']['membersCount']
                            });
                        case 'has_invite':
                            return resolve({
                                status:'has_invite',
                                invite: response.data['body']['invite']
                            });
                   }
                   if(response.status == 404){
                       if(response.description == 'event_not_found'){
                           return resolve({
                               status:'event_not_found'
                            })
                        }
                    }
                }).catch(err =>{
                   console.log(err)
                   return resolve({
                       status:'event_not_found'
                   })
               })
           })
        },

        createEvent ({state}, payload) {
            return new Promise((resolve, reject) => {
                axios({
                    url: '/events',
                    method: 'post',
                    data: payload
                })
                .then((response) => {
                    switch (response.data['status']) {
                        case 'event_created':
                            resolve({
                                message: 'success',
                                id: response.data['body']['id']
                            });
                        break;
                        default:
                            reject ('fail')
                        break;
                    }
                })
                .catch(err =>{
                    console.log(state.events)
                    return reject(err)
                })
            })
        },

        updateEvent ({state},payload){
            return new Promise((resolve, reject) => {
                axios({
                    url: '/events/'+ payload.id,
                    method: 'PUT',
                    data: payload
                }).then((response) => {
                    if(response.data['status'] == 'event_updated'){
                        return resolve({
                            message: 'success'
                        })
                    }else{
                        console.log('fail')
                        return reject ('fail')
                    }
                })
                .catch(err =>{
                    console.log(state)
                    console.log(err)
                })
            })
        },
        
        uploadPhoto({commit},payload){
            return new Promise((resolve,reject) => {
                axios({
                    url:'entities/' + payload.id +'/setCoverPhoto',
                    method: "POST",
                    headers: {'Content-Type':'multipart/form-data'},
                    data: payload.data
                })
                .then((response) =>{
                    if (response.data['status'] == 'cover_photo_updated') {
                        return resolve('success');
                    }else{
                        commit('updateCoverPhoto',require('@/assets/default_cover2.webp'))
                        return resolve('success')
                    }
                })
                .catch(err =>{
                    return reject(err)
                })
            })
        },
        getCoverPhoto({state},payload){
            return new Promise((resolve,reject) => {
                axios({
                    url:'/entities/' + payload.id + '/getCoverPhoto',
                    method: "GET"
                })
                .then((response) => {
                    if (response.data['status'] == 'cover_photo_retrieved') {
                        if (response.data['body']['file_id']) {
                            return resolve({
                                status: 'success',
                                image: response.data['body']['file_url']
                            });
                        }  
                    }
                    if(response.data['status'] == 'not_available'){
                        return resolve({
                            status: 'success',
                            image: require('@/assets/default_cover2.webp')
                        })
                    }
                })
                .catch(err =>{
                    console.log(state.event)
                    return reject(err)
                })
            })
        },
        // Update entities cover photo by media id
        UpdateCoverPhotoByID({state},payload){
            return new Promise((resolve,reject) => {
                axios({
                    url:'/entities/' + payload.id + '/setCoverPhoto?media_id='+payload.media_id,
                    method: "POST"
                })
                .then((response) => {
                    if (response.data['status'] == 'cover_photo_updated') {
                        if (response.data['body']['file_id']) {
                            return resolve({
                                status: 'success',
                                image: response.data['body']['file_url']
                            });
                        }  
                    }
                    if(response.data['status'] == 'not_available'){
                        return resolve({
                            status: 'success',
                            image: require('@/assets/default_cover2.webp')
                        })
                    }
                })
                .catch(err =>{
                    console.log(state.event)
                    return reject(err)
                })
            })
        },
        deleteEvent({state},payload){
            return new Promise((resolve, reject) => {
                axios({
                    url: '/events/'+payload,
                    method: "DELETE"
                })
                .then((r) => {
                    if(r.data['status'] == 'event_deleted'){
                        return resolve('success')
                    }
                })
                .catch(err => {
                    console.log(state.event)
                    return reject(err)
                })
            })
        },

        fetchMembers({state},payload){
            return new Promise((resolve, reject) => {
                axios({
                    url: '/events/'+payload.id+'/participants?page='+payload.page+'&limit=10&q='+payload.q,
                    method: "GET"
                })
                .then((r) => {
                    if(r.data['status'] == 'participants_retrieved'){
                        return resolve({
                            status: 'success',
                            participants: r.data['body']['participants'],
                            pagination: r.data['body']['pagination']
                        })
                    }
                })
                .catch(err => {
                    console.log(state.event)
                    return reject(err)
                })
            })
        },

        KickEventMembers({state},payload){
            return new Promise((resolve, reject) => {
                axios({
                    url: '/events/'+payload.id+'/kick?user_id='+payload.user_id,
                    method: "POST"
                })
                .then((r) => {
                    if(r.data['status'] == 'members_kicked'){
                        return resolve({
                            status: 'success',
                            participants: r.data['body']['participants'],
                            pagination: r.data['body']['pagination']
                        })
                    }else if(r.data['status'] == 'not_owner_admin'){
                        return resolve ({
                            status:'not_admin'
                        })
                    }else if(r.data['status'] == 'not_a_member'){
                        return resolve({
                            status: 'not_member'
                        })
                    }else if(r.data['status'] == 'event_not_found'){
                        return resolve({
                            status: 'event_not_found'
                        })
                    }else if(r.data['status'] == 'user_not_found'){
                        return resolve({
                            status: 'user_not_found'
                        })
                    }
                })
                .catch(err => {
                    console.log(state.event)
                    return reject(err)
                })
            })
        },

        transferEventOwnership({state},payload){
            return new Promise((resolve, reject) => {
                axios({
                    url: '/events/'+payload.id+'/transfer?user_id='+payload.user_id,
                    method: "POST"
                })
                .then((r) => {
                    if(r.data['status'] == 'event_transferred'){
                        return resolve({
                            status: 'success',
                        })
                    }else if(r.data['status'] == 'not_owner'){
                        return resolve ({
                            status:'not_owner'
                        })
                    }else if(r.data['status'] == 'add_as_member_error'){
                        return resolve({
                            status: 'add_as_member_error'
                        })
                    }else if(r.data['status'] == 'event_not_found'){
                        return resolve({
                            status: 'event_not_found'
                        })
                    }
                })
                .catch(err => {
                    console.log(state.event)
                    return reject(err)
                })
            })
        },

        FetchScoreBoard({commit},payload){
            return new Promise((resolve) => {
                axios({
                    url: '/events/'+payload.event_id+'/scorecards?hole='+payload.hole+'&round='+payload.round,
                    method: 'GET'
                })
                .then((r) => {
                    
                    if(r.data['status'] == 'event_scorecards_retrieved'){
                        //commit('UpdateEventPars',r.data['body']['event_pars'])
                        commit('UpdateEventScoreboard',r.data['body']['scorecards'])
                        var totals = {
                            parIn: r.data['body']['scorecards']['settings']['parIn'],
                            parOut: r.data['body']['scorecards']['settings']['parOut'],
                            totalpar: r.data['body']['scorecards']['settings']['totalPar'],
                        }
                        commit('UpdateEventTotals',totals)
                        return resolve({
                            status: 'scorecards_fetched'
                        })
                    }else{
                        commit('UpdateEventPars',[])
                        commit('UpdateEventScoreboard',[])
                        return resolve ({
                            status: 'no_scoreboard_found'
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    return resolve({
                        status: 'not_found'
                    })
                })
            })
            
        },

        UpdateScoreboard({state},payload){
            return new Promise((resolve, reject) =>{
                console.log(payload.score)
                axios({
                    url:'/events/'+payload.id+'/scorecards?hole='+payload.hole+'&round='+payload.round,
                    method:'PUT',
                    data: payload.score
                })
                .then((r) => {
                    if(r.data['status'] == 'hole_update_success'){
                        return resolve({
                            status: 'scoreboard_updated'
                        })
                    }
                })
                .catch(err =>{
                    console.log(state.event_scoreboard)
                    return reject(err)
                })
            })
        },

        UpdatePaidStatus({state},payload){
            return new Promise((resolve, reject) => {
                axios({
                    url:'events/'+payload.id+'/update_paid_status?user_id='+payload.user_id+'&paid='+payload.status,
                    method: 'PUT'
                })
                .then((r) => {
                    if(r.data['status'] == 'paid_status_updated'){
                        return resolve({
                            status:'success',
                        })
                    }
                })
                .catch(err => {
                    console.log(state.eventlist)
                    return reject(err)
                })
            })
        },

        CreateScoreboard({state},payload){
            return new Promise((resolve, reject) =>{
                axios({
                    url:'events/'+payload.id+'/scorecards',
                    method:'POST'
                })
                .then((r) =>{
                    if(r.data['status'] == 'scorecard_created'){
                        return resolve({
                            status: 'success'
                        })
                    }
                })
                .catch(err =>{
                    console.log(state.events)
                    return reject(err)
                })
            })
        },

        DeleteScorecard({state},payload){
            return new Promise((resolve, reject) =>{
                axios({
                    url:'/events/'+payload.id+'/scorecards/'+payload.user_id,
                    method: 'DELETE',
                })
                .then((r) =>{
                    if(r.data['status'] == 'scorecard_deleted'){
                        return resolve({
                            status:'success'
                        })
                    }
                })
                .catch(err => {
                    console.log(state.event_scoreboard);
                    return reject(err)
                })
            })
        }

    },

    mutations: {
        UpdateEventPars: (state, data) => ( state.event_pars = data ),
        UpdateEventScoreboard: (state, data) => ( state.event_scoreboard = data ),
        UpdateEventTotals: (state, data) => ( state.event_totals = data ),
        updateEvents: (state,payload) => ( state.events = payload ),
        updateEvent: (state, payload) => ( state.event = payload ),
        sortCalendarEvents: (state, events) => (
            state.calendar_events.events = [],
            state.calendar_events.events_data = [],
            events.map( (event) => {
                state.calendar_events.events.push(event.date_start.replaceAll('-','/')),
                state.calendar_events.events_data.push({
                    from: event.date_start.replaceAll('-','/'),
                    to: (event.date_end !== null) ? event.date_end.replaceAll('-','/') : null,
                    name: event.name,
                    id: event.id,
                    course:event.course_name,
                    time_start:event.time_start,
                    time_end:event.time_end,
                    venue: event.venue
                })
            })
        ),

        updateUpcomingEvents: (state, data) => ( state.upcoming_events = data),

    },
    namespaced: true
}

export default event