import axios from 'axios'
const profile = {
    state: () => ({
        full_name: null,
        profile_photo: '',
        cover_photo: '',
        init: null, 
        course_id: null,
        mobile_number: null,
        share_mobile_number: null,
        share_email_address: null,
        hometown: null,
        share_hometown: null,
        board_state: 'posts',
        isProfileUpdate: false,
        friends: []
        
    }),
    mutations: { 
        UpdateStateProfilePhoto(state, payload) {
            state.profile_photo = payload;
        },
        UpdateStateCoverPhoto(state,payload){
            state.cover_photo = payload;
        },
        SwitchState(state,payload) {
            state.board_state = payload
        },
        isUpdateProfileState(state,payload) {
            state.isProfileUpdate = payload
        },
        updateFriendsList(state,payload) {
            state.friends = payload
        }
    },
    actions: { 
        getProfilePhoto({state}, payload) {
            return new Promise((resolve,reject) => {
                axios({
                    url:'profiles/' + payload.user_id + '/getProfilePhoto',
                    method:'GET'
                })
                .then(response => {
                    if(response.data['status'] == 'profile_photo_retrieved'){
                        if (response.data['body']['profile_photo'] != 'not_available') {
                            return resolve({
                                status: 'success',
                                image: response.data['body']['file_url']
                            });
                        } else {
                            return resolve({
                                status: 'success',
                                image: require('@/assets/default_profile.webp')
                            });
                        }
                    }
                })
                .catch(err => {
                    console.err(state);
                    return reject(err)
                })
            })
        },
        
        // Upload and Set Profile Photo
        uploadProfilePhoto({commit},payload){
            return new Promise((resolve,reject) => {
                axios({
                    url:'profiles/setProfilePhoto',
                    method: 'POST',
                    headers: { 'Content-Type': 'multipart/form-data'},
                    data: payload.data
                })
                .then((response) =>{
                    if(response.data['status'] == 'profile_photo_updated'){
                        commit('UpdateStateProfilePhoto',response.data['body']['file_url'])
                        return resolve('upload_success')
                    }else{
                        return resolve('upload_fail')
                    }
                })
                .catch(err =>{
                    return reject(err)
                })
            })
        },
        uploadCoverPhoto({commit},payload){
            return new Promise((resolve,reject) => {
                axios({
                    url:'profiles/setCoverPhoto',
                    method: "POST",
                    headers: {'Content-Type':'multipart/form-data'},
                    data: payload.data
                })
                .then((response) =>{
                    if (response.data['status'] == 'cover_photo_updated') {
                            commit('UpdateStateCoverPhoto', response.data['body']['file_url'] )
                        return resolve('success');
                    }else{
                        commit('UpdateStateCoverPhoto',require('@/assets/default_cover2.webp'))
                        return resolve('success')
                    }
                })
                .catch(err =>{
                    return reject(err)
                })
            })
        },
        getCoverPhoto({state},payload){
            return new Promise((resolve,reject) => {
                axios({
                    url:'profiles/' + payload.id + '/getCoverPhoto',
                    method: "GET"
                })
                .then((response) =>{
                    if (response.data['status'] == 'profile_photo_retrieved') {
                        if (response.data['body']['cover_photo'] != 'not_available') {
                            state.cover_photo = response.data['body']['file_url']
                        } else {
                            state.cover_photo = require('@/assets/default_cover2.webp')
                        }
                        return resolve('success');
                    }
                })
                .catch(err =>{
                    return reject(err)
                })
            })
        },

        loadFriends({commit},payload){
            return new Promise((resolve,reject) => {
                axios({
                    url:'profiles/' + payload.id + '/friends?page=' + payload.page + '&q=' + payload.search,
                    method: 'GET'
                })
                .then((response) => {
                    if(response.data['status'] == 'friends_retrieved'){
                        commit('updateFriendsList',response.data['body']['friends'])
                        return resolve({
                            type:'success',
                            maxPages: response.data['body']['maxNumberOfPages'],
                            currentPage: response.data['body']['currentPage']
                        })
                    }else{
                        return resolve('failed')
                    }
                })
                .catch(err =>{
                    return reject(err)
                })
            })
        },

        getUser({state},payload){
            return new Promise((resolve,reject) => {
                axios({
                    url: 'profiles/' + payload,
                    method: 'GET'
                })
                .then((response) => {
                    if(response.data['status'] == 'profile_retrieved'){
                        
                        return resolve({
                            type:'success',
                            name: response.data['body']['profile']['first_name'],
                            data: response.data['body']['profile']
                        })
                    }else{
                        return resolve('failed')
                    }
                })
                .catch(err =>{
                    console.log(state)
                    return reject(err)
                })
            })
        },

        /**
         * Updates the profile photo by media id.
         *
         * @param { String } param0 
         * @param { String } payload 
         * @returns 
         */
        updateProfilePhotoById({commit}, payload){
            return new Promise((resolve,reject) => {
                axios({
                    url: 'profiles/setProfilePhoto?media_id=' + payload,
                    method: 'POST'
                })
                .then((response) => {
                    switch (response.data['status']) {
                        case 'profile_photo_updated':
                            commit('UpdateStateProfilePhoto', response.data['body']['file_url']);
                            return resolve({type: 'profile_photo_updated'});
                        case 'already_in_use':
                            return resolve({type: 'already_in_use'});
                        default:
                            return resolve({type: 'failed'});
                    }
                })
                .catch(err =>{
                    return reject(err)
                })
            })
        },

        UpdateCoverPhotoByID({commit},payload){
            return new Promise((resolve,reject) => {
                axios({
                    url: 'profiles/setCoverPhoto?media_id=' + payload,
                    method: 'POST'
                })
                .then((response) => {
                    if(response.data['status'] == 'cover_photo_updated'){
                        commit('UpdateStateCoverPhoto', response.data['body']['file_url'])

                        return resolve({
                            type:'success',
                        })
                    }else{
                        return resolve('failed')
                    }
                })
                .catch(err =>{
                    return reject(err)
                })
            })
        },

        DeleteAccount(_,payload){
            return new  Promise((resolve) => {
                axios({
                    url:'users/'+payload.id,
                    method:'DELETE',
                    data:payload.password
                })
                .then((response) => {
                    if(response.data['status'] == 'delete_success' ){
                        return resolve({
                            status:'success'
                        })
                    }else{
                        return resolve({
                            status : 'We cant process your request. Please check your password.'
                        })
                    }
                })
            })
        }

    }, 

    
    getters: { 
        getCoverPhoto: state => {
            return state.cover_photo
        },
        getFriends: state => {
            return state.friends
        }
    },
    namespaced: true
}
export default profile