const entities = {
    state: () => ({
        cache: [],
    }),
    getters: {
        read: (state) => (id) => {
            let entity = state.cache.find(entity => entity.id === id);
            if (entity !== undefined) {

                if (entity.timestamp === undefined) {
                    entity.timestamp = Date().getTime();
                }

                let currentTime = Date.now() - entity.timestamp;
                currentTime = Math.floor(currentTime / 1000);
                if (3600 < currentTime) {
                    return undefined;
                }

                return entity;
            }

            return undefined;
        }
    },
    mutations: {
        save (state, payload) {
            let entity = state.cache.find(post => post.id === payload.id);
            if (entity !== undefined) {
                entity.name = payload.name,
                entity.owner = payload.owner,
                entity.type = payload.type,
                entity.membersCount = payload.membersCount,
                entity.date_start = payload.date_start,
                entity.date_end = payload.date_end,
                entity.venue = payload.venue,
                entity.photo = payload.photo
                entity.timestamp = new Date().getTime();
            } else {
                if (payload.timestamp === undefined) {
                    payload.timestamp = new Date().getTime();
                }
                state.cache.unshift(payload)
            }
        },
        flush (state) {
            state.cache = [];
        }
    },
    namespaced: true
}
export default entities