import axios from 'axios'
const store = {
    state: () => ({
        items: [],
        store: [],
        item: [],
        storelist: [],
        itemList: [],
        cover_photo: '',
    }),
    getters: {
        suggestedItems: state => state.items,
        itemList: state => state.storelist,
        store: (state) =>  state.store,
        storeList: (state) => state.storelist 
    },
    actions: {
        createStore({state},payload){
            return new Promise((resolve,reject) => {
                axios({
                    url:'/stores',
                    method:'POST',
                    data:payload
                }).then((r) => {
                    if(r.data['status'] == 'store_created'){
                        state.store = r.data['body']
                        return resolve({
                            status:'success',
                            data:store
                        })
                    }else if(r.data['status'] == 'store_name_taken'){
                        return resolve({
                            status: 'store_name_taken'
                        })
                    }
                }).catch(err =>{
                    return reject(err)
                })
            })
        },
        createItems({state},payload){
            return new Promise((resolve,reject) => {
                axios({
                    url:'/stores/'+payload.store_id+'/items',
                    method:'POST',
                    data:payload.data
                }).then((r) => {
                    if(r.data['status'] == 'store_item_created'){
                        return resolve({
                            status:'success',
                            data:r.data['body']['id']
                        })
                    }
                }).catch(err =>{
                    console.log(state.item)
                    return reject(err)
                })
            })
        },
        // Fetch all stores
        fetchStores({state},payload){
            return new Promise((resolve,reject) => {
                axios({
                    url:'/stores?page='+payload.page+'&limit=5&q='+payload.q,
                    method: "GET",
                }).then((r) => {
                    if(r.data['status'] == 'stores_retrieved'){
                        state.storelist = r.data['body']['stores']
                        return resolve('success')
                    }else if(r.data['status'] == 'no_stores_found'){
                        return resolve(r.data['status'])
                    }else{
                        return resolve('Failed')
                    }
                })
                .catch(err => {
                    return reject(err)
                })
            })
        },

        // Fetch single store
        fetchStore({commit},payload){
            return new Promise((resolve,reject) => {
                axios({
                    url:'/stores/'+payload,
                    method: "GET",
                }).then((r) => {
                    if(r.data['status'] == 'store_retrieved'){
                        commit('setStore', r.data['body']['store'])
                        return resolve('success')
                    }else{
                        return reject('Failed')
                    }
                })
                .catch(err => {
                    console.log(err)
                    return resolve('store_not_found')
                })
            })
        },

        // Fetch all single store items
        fetchStoreItems({state},payload){
            return new Promise((resolve,reject) => {
                axios({
                    url:'/stores/'+payload.id+'/items?page='+payload.page+'&limit=5&q=',
                    method: "GET",
                }).then((r) => {
                    if(r.data['status'] == 'items_retrieved'){
                        state.itemList = r.data['body']['stores']
                        return resolve('success')
                    }else{
                        return reject('Failed')
                    }
                })
                .catch(err => {
                    return reject(err)
                })
            })
        },

        // Fetch single item
        fetchItem({state,commit},payload){
            return new Promise((resolve) => {
                axios({
                    url:'/stores/'+payload.store_id+'/items/'+payload.item_id,
                    method: "GET",
                }).then((r) => {
                    if(r.data['status'] == 'item_retrieved'){
                        r.data['body']['item']['is_sold'] == '0' ? r.data['body']['item']['is_sold'] = false : r.data['body']['item']['is_sold'] = true;
                        commit('setItemData',r.data['body']['item'])
                        return resolve({
                            status: 'success',
                            data: state.item
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    return resolve('item_not_found')
                })
            })
        },

        // Fetch Suggested Items
        fetchItems({state},payload){
            return new Promise((resolve,reject) => {
                axios({
                    url: '/stores/items?page='+payload.page+'&status=unsold_items',
                    method: "GET",
                }).then((r) => {
                    if(r.data['status'] == 'items_retrieved'){
                        state.itemList = r.data['body']['stores']
                        return resolve('success')
                    }else{
                        return reject('Failed')
                    }
                })
                .catch(err => {
                    return reject(err)
                })
            })
        },

        updateItem({state,commit},payload){
            return new Promise((resolve, reject) => {
                axios({
                    url:'/stores/'+payload.store_id + '/items/'+payload.item_id,
                    method: 'PUT',
                    data: payload.data
                })
                .then((r) => {
                    if(r.data['status'] == 'item_updated'){
                        payload.data.is_sold == 0 ? payload.data.is_sold = false : payload.data.is_sold = true
                        commit('setItemData',payload.data)
                        return resolve({
                            status: 'success'
                        })
                    }else{
                        console.log(state.item)
                        return reject('failed')
                    }
                })
                .catch(err => {
                    return reject(err)
                })
            })
        },

        updateStore({commit},payload){
            return new Promise((resolve, reject) =>{
                axios({
                    url:'/stores/'+payload.id,
                    method: 'PUT',
                    data: payload.data
                })
                .then((r) => {
                    if(r.data['status'] == 'store_updated'){
                        commit('setStore',payload.data)
                        return resolve('success')
                    }else{
                        return reject('Failed')
                    }
                })
                .catch(err => {
                    return reject(err)
                })
            })
        },

        uploadCoverPhoto({commit},payload){
            return new Promise((resolve,reject) => {
                axios({
                    url:'entities/' + payload.id +'/setCoverPhoto',
                    method: "POST",
                    headers: {'Content-Type':'multipart/form-data'},
                    data: payload.data
                })
                .then((response) =>{
                    if (response.data['status'] == 'cover_photo_updated') {
                            commit('updateCoverPhoto', response.data['body']['file_url'] )
                        return resolve('success');
                    }else{
                        commit('updateCoverPhoto',require('@/assets/default_cover2.webp'))
                        return resolve('success')
                    }
                })
                .catch(err =>{
                    return reject(err)
                })
            })
        },
        getCoverPhoto({state,commit},payload){
            return new Promise((resolve,reject) => {
                axios({
                    url:'entities/' + payload.id + '/getCoverPhoto',
                    method: "GET"
                })
                .then((response) =>{
                    if (response.data['status'] == 'cover_photo_retrieved') {
                        if (response.data['body']['file_id']) {
                            commit('updateStoreCoverPhoto', response.data['body']['file_url'])
                        } 
                    }else {
                        commit('updateStoreCoverPhoto',require('@/assets/default_store.jpg'))
                   }
                   return resolve({
                       status: 'success',
                       image: state.cover_photo
                   });
                })
                .catch(err =>{
                    return reject(err)
                })
            })
        },

        uploadItemMedias({state},payload){
            return new Promise((resolve,reject) => {
                axios({
                    url: '/stores/'+payload.store_id+'/items/'+payload.item_id+ '/gallery',
                    method: "POST",
                    headers: {'Content-Type':'multipart/form-data'},
                    data: payload.data
                })
                .then((r) => {
                    if(r.data['status'] == 'file_uploaded'){
                        return resolve({
                            status: 'success',
                            item_id: payload.item_id,
                            store_id:payload.store_id
                        })
                    }
                })
                .catch(err => {
                    console.log(state.item)
                    return reject(err)
                })
            })
        },

        fetchItemMedia({state},payload){
            return new Promise((resolve,reject) => {
                axios({
                    url: '/stores/'+payload.store_id+'/items/'+payload.item_id+ '/gallery',
                    method: "GET",
                })
                .then((r) => {
                    if(r.status == 200){
                        return resolve({
                            status: 'success',
                            medias: r.data['body']['medias']
                        })
                    }
                })
                .catch(err => {
                    console.log(state.item)
                    return reject(err)
                })
            })
        }
    },

    mutations: {
        setItemsData: (state, items) => (state.items = items),
        setItemData: (state, item) => (state.item = item),
        setStore: (state, store) => (state.store = store),
        updateStoreCoverPhoto: (state, payload) => (state.cover_photo = payload)
    },
    namespaced: true
}

export default store